// 라이브러리
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { message } from 'antd';

// 공통 컴포넌트
import { useQuery, useMutation } from '../../../hooks/useQuery';
import { SITE_ID, BBS_ID } from '../../../config/constants';

// 페이지
import NoticeDetailView from './NoticeDetailView';

/**
 * 회사소개
 * @returns
 */
function NoticeDetail() {
  const bbsId = BBS_ID['notice'];
  const { noticeid } = useParams();

  const initialSearchValues = {
    subPageIndex: 1,
    subPageSize: 100,
    subPageUnit: 100,
    bbsId,
    nttId: noticeid,
  };

  const [search, setSearch] = useState(initialSearchValues);
  const [paging, setPaging] = useState();
  const [isModalPwd, setIsModalPwd] = useState(false);
  const [confirmPwd, setConfirmPwd] = useState('');
  const [commentNo, setCommentNo] = useState('');
  const [prevNotice, setPrevNotice] = useState(null);
  const [nextNotice, setNextNotice] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const inputPwdRef = useRef(null);
  const inputConfirmPwdRef = useRef(null);

  /**
   * API - 상세정보 조회
   */
  const {
    data: detail,
    // refetch: refetchDetail,
    // dataUpdatedAt,
    // ...restDetailQuery
  } = useQuery(
    '/api/article/find',
    { siteId: SITE_ID, bbsId, nttId: noticeid },
    {
      cacheTime: 0,
      enabled: !!noticeid,
    },
  );

  /**
   * API - 이전글/다음글 조회
   */
  const { data: dataPrevNext } = useQuery(
    '/api/article/selectArticlePrevNext',
    { siteId: SITE_ID, bbsId, nttId: noticeid },
    {
      cacheTime: 0,
      enabled: !!noticeid,
    },
  );

  /**
   * API - 댓글 목록 조회
   */
  const {
    data: dataComments,
    refetch: refetchComments,
    // ...restQuery
  } = useQuery(
    '/api/comment/findAll',
    { ...search, nttId: noticeid },
    {
      cacheTime: 0,
      enabled: !!search,
      onSuccess: res => {
        if (res) {
          setPaging(res.paginationInfo);
        }
      },
    },
  );

  /**
   * API - 댓글저장
   */
  const saveCommentsMutation = useMutation('/api/comment/save');

  /**
   * API - 댓글삭제
   */
  const removeCommentsMutation = useMutation('/api/comment/remove');

  const formInitialValues = useMemo(
    () => ({
      isEdit: false,
      commentNo: '',
      writer: '',
      password: '',
      questions: '',
    }),
    [],
  );

  const formValidate = values => {
    const errors = {};
    const { writer, password, questions } = values;
    if (!writer) {
      errors.writer = '작성자를 입력해 주십시오.';
    }
    if (!password) {
      errors.password = '비밀번호를 입력해 주십시오.';
    } else if (password.length < 4) {
      errors.password = '비밀번호는 4자리 이상 입력해 주십시오.';
    }

    if (!questions) {
      errors.questions = '댓글을 입력해 주십시오.';
    }

    // console.log('🚀 ~ formValidate ~ errors:', errors);

    return errors;
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validate: formValidate,
    onSubmit: (values, { setSubmitting }) => {
      const sendValues = {
        ...values,
        isEdit,
        nttId: noticeid,
        bbsId,
        wrterNm: values.writer ?? '',
        commentPassword: values.password ?? '',
        commentCn: values.questions ?? '',
        wrterId: 'guest',
        frstRegisterId: 'guest',
      };

      if (isEdit) {
        const selectedComment = dataComments?.resultList.find(
          row => row.commentNo === values.commentNo,
        );

        if (selectedComment && !isEmptyObject(selectedComment)) {
          if (selectedComment.commentPassword !== values.password) {
            message.error('비밀번호가 틀립니다.');

            formik.setFieldValue('password', '');
            formik.setSubmitting(false);
            formik.validateForm();
            // console.log('🚀 ~ NoticeDetail ~ formik.values:', formik.values);
            if (inputPwdRef.current) {
              inputPwdRef.current.focus();
            }
            /*
            formik.resetForm();
            setIsEdit(false);
            setCommentNo('');
            */
            return;
          }
        }
      }

      // 댓글저장 API 호출
      saveCommentsMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            const retMsg = `${isEdit ? '수정' : '등록'} 되었습니다.`;
            message.success(retMsg);
            // refetch
            refetchComments();
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ onError : error', JSON.stringify(error));
        },
        onSettled: async () => {
          // Form Reset
          handleReset();
          setSubmitting(false);
        },
      });
    },
  });

  /**
   * 페이징 처리
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const handlePagination = (subPageIndex, subPageSize) => {
    setSearch(preSearch => ({ ...preSearch, subPageIndex, subPageSize }));
  };

  /**
   * 댓글 form reset
   */
  const handleReset = () => {
    formik.resetForm({ values: formInitialValues });
    setCommentNo('');
    setIsEdit(false);
  };

  /**
   * 댓글 수정 클릭 시
   * @param {*} params
   */
  const handleModifyComment = params => {
    const { commentNo } = params;

    const selectedComment = dataComments?.resultList.find(
      row => row.commentNo === commentNo,
    );

    if (selectedComment && !isEmptyObject(selectedComment)) {
      // 수정여부
      setIsEdit(true);
      const { wrterNm, commentCn } = selectedComment;
      // formik.setFieldValue('isEdit', true); // 수정여부
      formik.setFieldValue('commentNo', commentNo);
      formik.setFieldValue('isEdit', true);
      formik.setFieldValue('writer', wrterNm);
      formik.setFieldValue('questions', commentCn);
      formik.setFieldValue('password', '');

      // password focus
      inputPwdRef.current && inputPwdRef.current.focus();
    }
  };

  /**
   * 댓글 삭제 클릭 시
   * @param {*} params
   */
  const handleRemoveComment = params => {
    const { commentNo, confirmPwd } = params;

    // 대상 댓글 추출
    const findComment = dataComments.resultList.find(
      row => row.commentNo === commentNo,
    );
    // 댓글 비밀번호
    const commentPassword = findComment?.commentPassword;
    // 비밀번호 확인
    const isPwdConfirm = commentPassword === confirmPwd;

    if (isPwdConfirm) {
      // 댓글저장 API 호출
      removeCommentsMutation.mutate(
        { commentNo },
        {
          onSuccess: res => {
            if (res?.status === 200) {
              // Message
              message.success('삭제 되었습니다.');
              // refetch
              refetchComments();
            } else {
              const { status, statusText } = res;
              message.error(`[${status} - ${statusText}]`);
            }
          },
          onError: async error => {
            console.error('🚀 ~ onError : error', JSON.stringify(error));
          },
          onSettled: async () => {
            // console.log('🚀 ~ removeCommentsMutation onSettled!!!');
            // Form Reset
            handleReset();
          },
        },
      );
    } else {
      message.error('비밀번호가 틀립니다.');
    }

    setCommentNo('');
    setConfirmPwd('');
    setIsModalPwd(false);
  };

  /**
   * 확인 비밀번호 세팅
   * @param {*} pwd
   * @returns
   */
  const handleSetConfirmPwd = pwd => setConfirmPwd(pwd);

  /**
   * 댓글 삭제버튼 클릭 시
   * @param {*} rCommentNo
   */
  const handleClickRemoveComment = rCommentNo => {
    setCommentNo(rCommentNo);
    setIsModalPwd(true); // Modal Open
    setTimeout(() => {
      inputConfirmPwdRef.current && inputConfirmPwdRef.current.focus();
    }, 100);
  };

  /**
   * 비밀번호 확인 Modal Close
   */
  const handleCloseConfirmPwd = () => {
    setConfirmPwd('');
    setIsModalPwd(false); // Modal Close
  };

  /**
   * 댓글 input 변경시
   * @param {*} e
   */
  // const handleChangeComment = e => {
  //   e.preventDefault();
  //   const { name, value } = e.target;
  //   console.log('🚀 ~ handleChangeComment ~ name, value:', name, value);
  //   setComments(prev => ({ ...prev, [name]: value }));
  // };

  /** ***************************************************************
   *  useEffect
   *************************************************************** */
  // 이전글/다음글 세팅
  useEffect(() => {
    const noticeList = dataPrevNext?.noticeList;
    let sPrevNotice = null; // 이전글
    let sNextNotice = null; // 다음글
    if (noticeid && Array.isArray(noticeList) && noticeList.length > 0) {
      sPrevNotice = noticeList.find(row => row.nttId < noticeid);
      setPrevNotice(sPrevNotice);
      sNextNotice = noticeList.find(row => row.nttId > noticeid);
      setNextNotice(sNextNotice);
    }
  }, [dataPrevNext, noticeid]);

  // Scroll to Notice Title
  useEffect(() => {
    document.getElementById('noticeTitle')?.scrollIntoView();
  }, [noticeid]);

  /**
   * props 세팅
   */
  const props = {
    paging: { ...paging, onPaging: handlePagination },
    formik,
    detail,
    dataComments,
    confirmPwd,
    onSetConfirmPwd: handleSetConfirmPwd,
    isModalPwd,
    commentNo,
    prevNotice,
    nextNotice,
    isEdit,
    onCloseConfirmPwd: handleCloseConfirmPwd,
    onClickRemoveComment: handleClickRemoveComment,
    onModifyComment: handleModifyComment,
    onRemoveComment: handleRemoveComment,
    inputPwdRef,
    inputConfirmPwdRef,
  };

  return <NoticeDetailView {...props} />;
}

function isEmptyObject(param) {
  return Object.keys(param).length === 0 && param.constructor === Object;
}

export default NoticeDetail;
