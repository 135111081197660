import React from 'react';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/product/sub-visual.jpg';

function JoinRegistView() {
  return (
    <StyledSubContainer
      isVisible
      title="Member"
      subTxt=" 홈페이지에 오신 것을 환영합니다."
      subVisual={SubVisualImg}
      className="sub-member"
    >
      <article className="c-join-regist">
        <div className="inner-container__sub">
          <h3>회원가입</h3>
          <ol className="agree-step">
            <li>
              <span>Step1</span>
              <strong>약관동의</strong>
            </li>
            <li>
              <span>Step2</span>
              <strong>본인 인증</strong>
            </li>
            <li className="active">
              <span>Step3</span>
              <strong>회원정보 입력</strong>
            </li>
            <li>
              <span>Step4</span>
              <strong>가입 완료</strong>
            </li>
          </ol>
          <h4>회원정보 입력</h4>
          <form
            id="joinRegistForm"
            name="joinRegistForm"
            className="join-regist-form"
            // onSubmit={onSubmit}
          >
            <fieldset>
              <legend>회원정보 입력</legend>
              <div className="join-regist-form__wrap">
                <div className="join-regist-form__item">
                  <label htmlFor="name">이름</label>
                  <div className="form-inline-box">홍길동</div>
                </div>
                <div className="join-regist-form__item">
                  <label htmlFor="id">아이디</label>
                  <div className="form-inline-box">mrhong</div>
                </div>
                <div className="join-regist-form__item">
                  <label htmlFor="password" className="required">
                    비밀번호
                  </label>
                  <input
                    type="text"
                    id="password"
                    name="password"
                    // value={company}
                    placeholder="비밀번호를 입력해주세요."
                    // onChange={onChangeSubject}
                  />
                </div>
                <div className="join-regist-form__item">
                  <label htmlFor="passwordChk" className="required">
                    비밀번호 확인
                  </label>
                  <input
                    type="text"
                    id="passwordChk"
                    name="password"
                    // value={company}
                    placeholder="비밀번호를 재입력해주세요."
                    // onChange={onChangeSubject}
                  />
                </div>
              </div>
              <div className="join-regist-form__btn">
                <Button type="submit" size="md" variant="default">
                  가입하기
                </Button>
              </div>
            </fieldset>
          </form>
        </div>
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  h3,
  .info-txt {
    text-align: center;
  }
  .agree-step {
    /* display: table;
    table-layout: fixed; */
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 4rem;
    > li {
      position: relative;
      counter-increment: stepNum;
      flex: 1;
      /* display: table-cell; */
      text-align: center;
      color: #ff6347;
      &::before {
        content: '✓';
        display: block;
        margin: 0 auto 4px;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-weight: bold;
        border: 2px solid #ff6347;
        border-radius: 50%;
        background-color: #fff;
      }
      &:after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: #ff6347;
        position: absolute;
        top: 20px;
        left: 50%;
        z-index: -1;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &.active {
        &:before {
          content: counter(stepNum);
          font-family: inherit;
          font-weight: 700;
          color: #fff;
          background-color: #ff6347;
          border-color: #ff6347;
        }
        &:after {
          background-color: #ededed;
        }
        ~ li {
          color: #808080;
          &:before {
            content: counter(stepNum);
            font-family: inherit;
            font-weight: 700;
            background-color: #ededed;
            border-color: #ededed;
          }
          &:after {
            background-color: #ededed;
          }
        }
      }
      span {
        text-transform: uppercase;
      }
      strong {
        display: block;
        font-weight: 500;
      }
    }
  }
  .join-regist-form {
    &__wrap {
      padding: 3.2rem;
      border: 1px solid var(--color-gray-d9d9d9);
    }
    &__item {
      display: inline-flex;
      width: calc(100% / 2);
      font-size: 1.6rem;
      &:nth-child(n + 3) {
        padding-top: 1.4rem;
        margin-top: 1.4rem;
        border-top: 1px solid var(--color-gray-f5f5f5);
      }
      label {
        flex: 0 1 20rem;
        &.required {
          &::after {
            content: '*';
            margin-left: 5px;
            color: var(--color-red-FF3200);
          }
        }
      }
    }
    &__btn {
      margin-top: 2.4rem;
      text-align: center;
      button {
        position: relative;
        & + button {
          margin-left: 1rem;
        }
      }
    }
  }
`;

export default JoinRegistView;
