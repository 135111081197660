import React from 'react';
import styled from 'styled-components';

import SubContainer from '../../atoms/SubContainer';
import Button from '../../atoms/Button';
import SubVisualImg from '../../../assets/img/product/sub-visual.jpg';

function SurveyRegistView() {
  return (
    <StyledSubContainer
      title="설문조사"
      // subTxt=" 홈페이지에 오신 것을 환영합니다."
      subVisual={SubVisualImg}
      className="sub-survey"
    >
      <div className="inner-container__sub">
        <form
          id="surveyForm"
          name="surveyForm"
          className="survey-form"
          // onSubmit={onSubmit}
        >
          <fieldset>
            <legend>로그인</legend>
            <article className="c-survey c-survey__regist">
              <div className="c-survey__regist--header">
                <div className="title">
                  <strong>
                    <span>설문주제</span>
                  </strong>
                  <p>홈페이지 사용자 만족도 설문조사</p>
                </div>
                <p className="explain">
                  2020년도 지방공무원 공개경쟁신규임용 필기시험일이 다음과 같이
                  확정되었음을 알려드리오니 참고하시기 바랍니다.
                </p>
                <ul className="info">
                  <li>
                    <strong>목적</strong>
                    <span className="target">
                      2020년도 지방공무원 7급 공채 수험생(응시자) 설문조사
                    </span>
                  </li>
                  <li>
                    <strong>설문대상</strong>
                    <span className="target">대상1</span>
                  </li>
                  <li>
                    <strong>설문기간</strong>
                    <time className="date">2022-04-18 ~ 2022-05-06</time>
                  </li>
                </ul>
              </div>
              <div className="c-survey__regist--body">
                <dl className="regist__list">
                  <dt>
                    <span>문항 1</span>
                    <strong>본인의 목표는 무엇입니까!</strong>
                  </dt>
                  <dd>
                    <ul>
                      <li>
                        <span>
                          <input
                            type="radio"
                            id="question1-1"
                            name="question1-group"
                            checked
                          />
                          <label htmlFor="question1-1">7급 공채</label>
                        </span>
                      </li>
                      <li>
                        <span>
                          <input
                            type="radio"
                            id="question1-2"
                            name="question1-group"
                          />
                          <label htmlFor="question1-2">9급 공채</label>
                        </span>
                      </li>
                      <li>
                        <span>
                          <input
                            type="radio"
                            id="question1-3"
                            name="question1-group"
                          />
                          <label htmlFor="question1-3">연구지도직</label>
                        </span>
                      </li>
                    </ul>
                  </dd>
                </dl>
                <dl className="regist__list">
                  <dt>
                    <span>문항 2</span>
                    <strong>객관식 문항 + 기타 입력 문항 테스트</strong>
                  </dt>
                  <dd>
                    <ul>
                      <li>
                        <span>
                          <input
                            type="radio"
                            id="question2-1"
                            name="question2-group"
                            checked
                          />
                          <label htmlFor="question2-1">객관식 문항 1</label>
                        </span>
                      </li>
                      <li>
                        <span>
                          <input
                            type="radio"
                            id="question2-2"
                            name="question2-group"
                          />
                          <label htmlFor="question2-2">객관식 문항 2</label>
                        </span>
                      </li>
                      <li>
                        <span>
                          <input
                            type="radio"
                            id="question2-3"
                            name="question2-group"
                          />
                          <label htmlFor="question2-3">객관식 문항 3</label>
                        </span>
                      </li>
                      <li>
                        <span>
                          <input
                            type="radio"
                            id="question2-4"
                            name="question2-group"
                          />
                          <label htmlFor="question2-4">기타</label>
                          <input
                            type="text"
                            id="1996"
                            name=""
                            title="기타 입력"
                            style={{ width: '90%' }}
                          />
                        </span>
                      </li>
                    </ul>
                  </dd>
                </dl>
                <dl className="regist__list">
                  <dt>
                    <span>문항 3</span>
                    <strong>좋아하는 대사를 알려주세요</strong>
                  </dt>
                  <dd>
                    <ul>
                      <li>
                        <span>
                          <input
                            type="checkbox"
                            id="question3-1"
                            name="question3-group"
                            checked
                          />
                          <label htmlFor="question3-1">안녕하세요</label>
                        </span>
                      </li>
                      <li>
                        <span>
                          <input
                            type="checkbox"
                            id="question3-2"
                            name="question3-group"
                          />
                          <label htmlFor="question3-2">퇴근할게요</label>
                        </span>
                      </li>
                      <li>
                        <span>
                          <input
                            type="checkbox"
                            id="question3-3"
                            name="question3-group"
                          />
                          <label htmlFor="question3-3">처음뵙겠습니다</label>
                        </span>
                      </li>
                    </ul>
                  </dd>
                </dl>
                <dl className="regist__list">
                  <dt>
                    <span>문항 4</span>
                    <strong>
                      지방직 7급 공개경쟁 임용시험에 대해 본인이 생각하는 바를
                      서술해주십시오
                    </strong>
                  </dt>
                  <dd>
                    <ul>
                      <li>
                        <span>
                          <label htmlFor="questions" className="sr-only">
                            문의사항
                          </label>
                          <textarea
                            id="questions"
                            name="questions"
                            rows="8"
                            placeholder=""
                            style={{ width: '100%' }}
                          />
                        </span>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
              <div className="c-survey__btn">
                <Button type="button" variant="default">
                  설문완료
                </Button>
                <Button href="/survey/" variant="info">
                  목록으로
                </Button>
              </div>
            </article>
          </fieldset>
        </form>
      </div>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  h3,
  .info-txt {
    text-align: center;
  }
  .c-survey {
    /* 설문조사 등록 */
    [class*='--header'] {
      padding: 2.4rem;
      border: 1px solid var(--color-gray-d9d9d9);
      .title {
        display: flex;
        font-size: 2.4rem;
        strong {
          font-weight: 500;
          span {
            margin-right: 2rem;
            padding: 0.8rem 1.6rem 0.8rem 1rem;
            color: #fff;
            font-size: 1.9rem;
            border-radius: 0 4rem 4rem 0;
            background: var(--color-main);
            box-shadow: 4px 6px 6px var(--color-black-rgba-10);
          }
        }
        p {
          margin-top: 0.4rem;
          font-weight: 600;
        }
      }
      .explain {
        margin-top: 2.4rem;
        font-size: 1.6rem;
      }
      .info {
        display: flex;
        flex-direction: column;
        padding: 1.6rem;
        margin-top: 1.6rem;
        background: var(--color-gray-f7f7f7);
        li {
          display: flex;
          & + li {
            margin-top: 1rem;
          }
          strong {
            position: relative;
            display: inline-block;
            margin-right: 3rem;
            flex: 0 0 8rem;
            &::after {
              content: '';
              position: absolute;
              right: -1.6rem;
              top: 0.4rem;
              width: 1px;
              height: 14px;
              border-left: 1px solid var(--color-gray-d9d9d9);
            }
          }
          .date {
            color: var(--color-gray-737373);
          }
        }
      }
    }
    [class*='--body'] {
      margin-top: 5rem;
      .regist__list {
        & + .regist__list {
          margin-top: 3.2rem;
          padding-top: 3.2rem;
          border-top: 1px solid var(--color-gray-eaeaea);
        }
        dt {
          display: flex;
          align-items: center;
          padding-bottom: 2rem;
          margin-bottom: 2rem;
          border-bottom: 1px dashed var(--color-gray-eaeaea);
          span {
            color: #fff;
            padding: 0.4rem 1rem;
            margin-right: 1rem;
            border-radius: 10rem;
            background: #586270;
            box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.1);
          }
          strong {
            flex: 1;
            font-size: 1.9rem;
          }
        }
        dd {
          ul {
            li {
              position: relative;
              & + li {
                margin-top: 1rem;
              }
              label {
                & + input {
                  margin-left: 1rem;
                }
              }
            }
          }
        }
      }
    }
    /* 버튼영역 */
    &__btn {
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin-top: 3.2rem;
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-survey {
      [class*='--header'] {
        .title {
          flex-direction: column;
          strong {
            margin-bottom: 1rem;
            span {
              font-size: 1.7rem;
            }
          }
        }
      }
    }
  }
`;

SurveyRegistView.defaultProps = {};

export default SurveyRegistView;
