import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import SubContainer from '../../atoms/SubContainer';

function SearchView() {
  return (
    <StyledSubContainer
      title="통합검색"
      // subTxt=" 홈페이지에 오신 것을 환영합니다."
      className="sub-search"
    >
      <article className="c-search">
        <div className="inner-container__sub">
          <h3 className="sr-only">통합검색</h3>
          <div className="c-total-search">
            <form id="totalSearchForm" name="totalSearchForm">
              <fieldset>
                <legend>통합검색</legend>
                <div className="c-total-search__input">
                  <select name="searchType" id="searchType">
                    <option value="total">전체</option>
                    <option value="title">제목</option>
                    <option value="contents">내용</option>
                  </select>
                  <input
                    type="text"
                    id="searchWrd"
                    name="searchWrd"
                    placeholder="검색어를 입력하세요."
                    value=""
                  />
                  <button type="submit" className="btn-search">
                    <i className="ri-search-line" />
                    <span className="sr-only">검색</span>
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
          <p className="info-txt">
            검색하신 <strong>&quot;소개&quot;</strong> 에 대한{' '}
            <strong>206</strong>개의 검색 결과가 있습니다.
          </p>
          <div className="total-search">
            <ul className="total-search__tab">
              <li className="active">
                <Link to="#">통합검색</Link>
              </li>
              <li>
                <Link to="#">메뉴검색</Link>
              </li>
              <li>
                <Link to="#">웹페이지</Link>
              </li>
              <li>
                <Link to="#">게시판</Link>
              </li>
            </ul>
            <div className="total-search__cont">
              <div className="search-list">
                <div className="search-list__item">
                  <strong>
                    메뉴 검색결과 <span>총 4건</span>
                  </strong>
                  <ul className="item-list">
                    <li>
                      <Link to="#">
                        사업<span className="highlight">소개</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        회사소개 &#62; CEO
                        <span className="highlight">소개</span>
                      </Link>
                    </li>
                  </ul>
                  <Link to="#" className="btn-more">
                    더보기 <i className="ri-add-fill" />
                  </Link>
                </div>
                <div className="search-list__item">
                  <strong>
                    웹페이지 검색결과 <span>총 24건</span>
                  </strong>
                  <ul className="item-list">
                    <li>
                      <dl>
                        <dt>
                          <Link to="#">
                            기술사업 <span className="highlight">소개</span>영상
                          </Link>
                        </dt>
                        <dd>
                          커뮤니케이션 가능한 공간을 생성/삭제할 수 있는
                          커뮤니티 관리 기능을 제공합니다. 다양한 속성의
                          게시판을 사용할 수 있도록 기능을 제공합니다. 정적인
                          컨텐츠 페이지를 생성하여 사이트에 추가할 수 있습니다.
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>
                          <Link to="#">
                            회사소개 &#62; CEO
                            <span className="highlight">소개</span>
                          </Link>
                        </dt>
                        <dd>
                          커뮤니케이션 가능한 공간을 생성/삭제할 수 있는
                          커뮤니티 관리 기능을{' '}
                          <span className="highlight">소개</span>합니다. 다양한
                          속성의 게시판을 사용할 수 있도록 기능을 제공합니다.
                          정적인 컨텐츠 페이지를 생성하여 사이트에 추가할 수
                          있습니다.
                        </dd>
                      </dl>
                    </li>
                  </ul>
                  <Link to="#" className="btn-more">
                    더보기 <i className="ri-add-fill" />
                  </Link>
                </div>
                <div className="search-list__item">
                  <strong>
                    게시판 검색결과 <span>총 24건</span>
                  </strong>
                  <ul className="item-list">
                    <li>
                      <p className="item-Breadcrumb">
                        이용안내 &#62; 자주묻는질문 &#62; 교육<span>소개</span>
                      </p>
                      <dl>
                        <dt>
                          <Link to="#">
                            기술사업 <span className="highlight">소개</span>영상
                          </Link>
                          <span className="date">2023-02-11</span>
                        </dt>
                        <dd>
                          커뮤니케이션 가능한 공간을 생성/삭제할 수 있는
                          커뮤니티 관리 기능을 제공합니다. 다양한 속성의
                          게시판을 사용할 수 있도록 기능을 제공합니다. 정적인
                          컨텐츠 페이지를 생성하여 사이트에 추가할 수 있습니다.
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <p className="item-Breadcrumb">
                        이용안내 &#62; 자주묻는질문 &#62; 교육<span>소개</span>
                      </p>
                      <dl>
                        <dt>
                          <Link to="#">
                            회사소개 &#62; CEO
                            <span className="highlight">소개</span>
                          </Link>
                          <span className="date">2023-02-11</span>
                        </dt>
                        <dd>
                          커뮤니케이션 가능한 공간을 생성/삭제할 수 있는
                          커뮤니티 관리 기능을 제공합니다. 다양한 속성의
                          게시판을 사용할 수 있도록 기능을 제공합니다. 정적인
                          컨텐츠 페이지를 생성하여 사이트에 추가할 수 있습니다.
                        </dd>
                      </dl>
                    </li>
                  </ul>
                  <Link to="#" className="btn-more">
                    더보기 <i className="ri-add-fill" />
                  </Link>
                </div>
              </div>
              <div className="search-word">
                <div className="search-word__item">
                  <strong>인기 검색어</strong>
                  <ol className="item-list ol-list__num">
                    <li>
                      <Link to="#">소개</Link>
                    </li>
                    <li>
                      <Link to="#">CMS</Link>
                    </li>
                    <li>
                      <Link to="#">사업</Link>
                    </li>
                    <li>
                      <Link to="#">홈페이지</Link>
                    </li>
                    <li>
                      <Link to="#">플레이그라운드</Link>
                    </li>
                  </ol>
                </div>
                <div className="search-word__item">
                  <strong>내가 찾은 검색어</strong>
                  <ul className="item-list item-list--tag">
                    <li>
                      <Link to="#">소개</Link>
                      <Button type="button" variant="link" size="xs">
                        <i className="ri-close-fill" />
                      </Button>
                    </li>
                    <li>
                      <Link to="#">CMS</Link>
                      <Button type="button" variant="link" size="xs">
                        <i className="ri-close-fill" />
                      </Button>
                    </li>
                    <li>
                      <Link to="#">플레이그라운드</Link>
                      <Button type="button" variant="link" size="xs">
                        <i className="ri-close-fill" />
                      </Button>
                    </li>
                  </ul>
                </div>
                <div className="search-word__item">
                  <strong>인기 검색어</strong>
                  <div className="item-tag">
                    <Link to="#">소개</Link>
                    <Link to="#">CMS</Link>
                    <Link to="#">플레이그라운드</Link>
                    <Link to="#">사업</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </StyledSubContainer>
  );
}
const StyledSubContainer = styled(SubContainer)`
  h3,
  .info-txt {
    text-align: center;
  }
  .inner-container {
    .info-txt {
      font-size: 2.5rem;
      margin-bottom: 2.4rem;
      strong {
        color: var(--color-main);
      }
    }
  }
  .c-total-search {
    padding: 4rem 2.4rem;
    margin-bottom: 5rem;
    background: var(--color-gray-F6F8FC);
    &__input {
      display: flex;
      align-items: center;
      max-width: 58rem;
      height: 50px;
      margin: 0px auto;
      select {
        width: 12rem;
        height: 4.8rem;
        padding: 0;
        line-height: 4.8rem;
        font-size: 1.7rem;
        border-bottom: 2px solid var(--color-black);
        background: transparent;
        & + input {
          margin-left: 1.6rem;
        }
      }
      input {
        flex: 1;
        height: 4.8rem;
        line-height: 4.8rem;
        font-size: 1.8rem;
        border: 0;
        padding: 0 3rem 0 0;
        border-bottom: 2px solid var(--color-black);
        background: transparent;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.2rem;
        height: 4.2rem;
        margin-left: 1rem;
        color: var(--color-white);
        font-size: 2rem;
        border-radius: 50%;
        background: var(--color-black);
      }
    }
  }
  .total-search {
    &__tab {
      display: flex;
      margin-bottom: 3.2rem;
      li {
        flex: 1;
        a {
          display: block;
          padding: 1.4rem 1rem;
          color: var(--color-black);
          font-size: 1.6rem;
          text-align: center;
          border: 1px solid #eee;
        }
        & + li {
          margin-left: -1px;
        }
        &.active {
          a {
            color: var(--color-white);
            background-color: var(--color-main);
          }
        }
      }
    }
    &__cont {
      display: flex;
      padding-top: 3.2rem;
      border-top: 2px solid var(--color-black);
      .search-list {
        flex: 1;
        padding: 3.2rem;
        border: 1px solid var(--color-gray-eaeaea);
        &__item {
          position: relative;
          & + [class*='__item'] {
            margin-top: 5rem;
          }
          strong {
            display: flex;
            align-items: flex-end;
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            font-size: 2.1rem;
            border-bottom: 1px solid var(--color-gray-c8c8c8);
            span {
              margin-left: 1rem;
              color: var(--color-main);
              font-size: 1.8rem;
              font-weight: 500;
            }
          }
          .item-list {
            li {
              padding-bottom: 1.6rem;
              margin-bottom: 1.6rem;
              border-bottom: 1px dashed var(--color-gray-eaeaea);
              a {
                color: var(--color-gray-3c3c3c);
                font-size: 1.8rem;
              }
              .item-Breadcrumb {
                color: var(--color-gray-737373);
                margin-bottom: 0.5rem;
              }
              dl {
                dt {
                  display: flex;
                  align-items: flex-end;
                  & + dd {
                    margin-top: 1rem;
                  }
                  .date {
                    margin-left: 1rem;
                    color: var(--color-gray-737373);
                  }
                }
                dd {
                  color: var(--color-gray-3c3c3c);
                }
              }
              .highlight {
                position: relative;
                color: var(--color-main);
                font-weight: 500;
                &::before {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  height: 8px;
                  background: var(--color-2abfb8-rgba-20);
                }
              }
            }
          }
        }
      }
      .search-word {
        &__item {
          strong {
            display: block;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            font-size: 1.7rem;
            border-bottom: 1px solid var(--color-gray-c8c8c8);
          }
          .item-tag {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0.8rem;
            a {
              display: inline-flex;
              padding: 0.4rem 1.6rem;
              border-radius: 3rem;
              border: 1px solid var(--color-gray-eaeaea);
              i {
                margin-left: 0.5rem;
              }
            }
          }
          .item-list {
            li {
              padding-bottom: 0.8rem;
              margin-bottom: 0.8rem;
              border-bottom: 1px dashed var(--color-gray-eaeaea);
              &:first-child {
                &:before {
                  color: var(--color-white);
                  border-color: var(--color-gray-737373);
                  background-color: var(--color-gray-737373);
                }
              }
              a {
                color: var(--color-gray-3c3c3c);
              }
            }
            &--tag {
              li {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }
          }
        }
      }
      .btn-more {
        position: absolute;
        top: 1.2rem;
        right: 0;
        color: var(--color-gray-737373);
      }
    }
  }
  @media ${({ theme }) => theme.media.xlMin} {
    .total-search {
      &__cont {
        .search-list {
          & + .search-word {
            margin-left: 3.4rem;
          }
        }
        .search-word {
          flex: 0 1 30rem;
          &__item {
            & + [class*='__item'] {
              margin-top: 5rem;
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.xl} {
    .total-search {
      &__cont {
        flex-direction: column;
      }
    }
  }

  @media ${({ theme }) => theme.media.smMinxl} {
    .total-search {
      &__cont {
        .search-word {
          display: flex;
          gap: 4rem;
          margin-top: 4rem;
          .search-word {
            &__item {
              width: calc(100% / 3);
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .total-search {
      &__cont {
        .search-word {
          margin-top: 3rem;
        }
      }
    }
  }
`;

export default SearchView;
