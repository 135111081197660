import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import MVisualImg from '../../../assets/img/main/m-visual-bg.jpg';
import MOurWorkImg from '../../../assets/img/main/m-ourwork-bg.jpg';
import WorkSkSustainabilityImg from '../../../assets/img/main/work-sk-sustainability-img.jpg';
import WorkCatchfunImg from '../../../assets/img/main/work-catchfun-img.jpg';
import WorkKoreantistImg from '../../../assets/img/main/work-koreantist-img.jpg';
import ProductCmsImg from '../../../assets/img/main/product-cms-img.jpg';
import ArrowIcon from '../../../assets/img/main/ico-arrow.png';

// 모달 테스트
import CustomModal from '../../molecules/CustomModal';
import CustomSwiper from '../../molecules/CustomSwiper/CustomSwiper';
import PopupZone from '../../organisms/PopupZone';

import LayerPopup from '../../organisms/LayerPopup/LayerPopup';

function MainView({ popups, banners }) {
  const swiperRef = useRef();

  // 커스텀 스와이프에 전달하기 위한 slider 목록
  const sliders = [
    {
      href: '#',
      img: WorkSkSustainabilityImg,
      alt: 'SK hynix 지속가능성 시스템 사이트',
      title: '2022',
      content: 'SK hynix 지속가능성 시스템 사이트 구축',
    },
    {
      href: '#',
      img: WorkCatchfunImg,
      alt: 'Catch fun!',
      title: '2022',
      content: '위치지도기반 쿠폰 서비스 Catch fun!',
    },
    {
      href: '#',
      img: WorkKoreantistImg,
      alt: '코리안티스트',
      title: '2022',
      content: '한글의 우수성과 독창성을 알리는 코리안티스트',
    },
    {
      href: '#',
      img: WorkSkSustainabilityImg,
      alt: 'SK Hynix 소통 플랫폼 구축',
      title: '2022',
      content: 'SK Hynix 소통 플랫폼 구축',
    },
    {
      href: '#',
      img: WorkCatchfunImg,
      alt: 'SSK Hynix ESG 사이트 구축',
      title: '2022',
      content: 'SK Hynix ESG 사이트 구축',
    },
  ];

  const [isVisible, setIsVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  // const [isPopupVisible, setIsPopupVisible] = useState(true);
  const [prev, setPrev] = useState(1);
  const [total, setTotal] = useState(1);

  const handleLayerPopupClose = bool => {
    setIsPopupVisible(false);
  };

  return (
    <>
      <MainContainer id="content">
        <article className="c-visual">
          <div className="c-visual__bg">
            <p className="img" />
          </div>
          <div className="container">
            <div className="c-visual__text">
              <div className="text-title">
                <span>바능에게</span>
                <span>반응하라</span>
              </div>
              <p className="text-type1">
                바능은 새로운 Reactive 패러다임을 만들어 갑니다.
              </p>
              <p className="text-type2">
                바능과 함께 새로운 패러다임에 동참하세요.
              </p>
              <p className="text-type3">바능은 React 전문 기업 입니다.</p>
            </div>
          </div>
          <div className="scroll-down">
            <p>SCROLL</p>
            <div className="scroll-down__bg">
              <div className="scroll-down__bar" />
            </div>
          </div>
        </article>
        <article className="c-work">
          <div className="container">
            <div className="inner">
              <div className="c-work__title">
                <h2>Our work</h2>
                <p className="info-txt">
                  바능은 새로운 기술에 도전하고, <br />
                  파트너와의 동반성장을 중요하게 생각합니다.
                </p>
                <div className="work-swiper__control">
                  <button
                    type="button"
                    onClick={() => swiperRef.current?.slidePrev()}
                    className="swiper-button-prev"
                  >
                    <span className="sr-only">이전</span>
                  </button>
                  <div className="swiper-pagination swiper-pagination-fraction">
                    <span className="swiper-pagination-current">
                      {`${prev}`.padStart(2, '0')}
                    </span>
                    <span>/</span>
                    <span className="swiper-pagination-total">
                      {`${total}`.padStart(2, '0')}
                    </span>
                  </div>
                  <button
                    type="button"
                    onClick={() => swiperRef.current?.slideNext()}
                    className="swiper-button-next"
                  >
                    <span className="sr-only">다음</span>
                  </button>
                </div>
                {/* <Button
                  variant="error"
                  onClick={() => {
                    setIsVisible(true);
                  }}
                >
                  모달 On
                </Button> */}
              </div>
              <div className="c-work__list">
                <CustomSwiper
                  ref={swiperRef}
                  {...{ sliders, setPrev, setTotal }}
                />
              </div>
            </div>
          </div>
        </article>
        <article className="c-playground">
          <div className="container">
            <div className="inner">
              <div className="c-playground__title">
                <h2>PRODUCT</h2>
                <p className="info-txt--sub">
                  <strong>CMS</strong> <span>(Contents Management System)</span>
                </p>
                <h3>Playground</h3>
                <p className="info-txt">
                  플레이그라운드는 React JS + Spring Boot + <br />
                  전자정부 프레임워크 기반의 CMS 솔루션 입니다.
                </p>
                <Button
                  href="/playground/"
                  variant="default"
                  className="c-playground__link"
                >
                  <span>Playground</span>
                  {/* <i className="ri-arrow-right-line" /> */}
                </Button>
              </div>
              <div className="c-playground__list">
                <img src={ProductCmsImg} alt="" />
              </div>
            </div>
          </div>
        </article>
      </MainContainer>
      <div>
        {isVisible && (
          <CustomModal
            message="테스트트트트트"
            onClose={() => {
              setIsVisible(false);
            }}
          />
        )}
      </div>

      {/* {isPopupVisible && (
          // <PopupZone
          //   onClose={() => {
          //     setIsPopupVisible(false);
          //   }}
          // />
          // <PopupContent />
        )} */}
      {isPopupVisible &&
        popups &&
        popups.map(popup => {
          const props = {
            popup,
            onClose: handleLayerPopupClose,
          };

          return (
            <div>
              <LayerPopup {...props} />
            </div>
          );
        })}
    </>
  );
}

const MainContainer = styled.section`
  overflow-x: hidden;
  .container {
    position: relative;
    max-width: var(--cantainer-width);
    /* height: 100vh; */
    margin: 0 auto;
    padding: 0 1.6rem;
    text-align: center;
    transition: all 0.3s ease;
    h2 {
      /* font-size: 10rem; */
      font-weight: 800;
      line-height: 1;
      text-transform: uppercase;
      & + .info-txt {
        margin-top: 2rem;
      }
    }
    h3 {
      font-size: 3.2rem;
      font-weight: 700;
      & + .info-txt {
        margin-top: 1rem;
      }
    }
    .info-txt {
      font-size: 2.4rem;
      span {
        font-weight: 300;
      }
      &--sub {
        font-size: 2rem;
        & + h3 {
          margin-top: 5rem;
        }
      }
    }
  }
  .c-visual {
    position: relative;
    left: 0;
    top: 0;
    height: 100vh;
    overflow: hidden;
    .container {
      height: 100vh;
    }
    &__bg {
      position: absolute;
      transition: all 0.6s ease-out;
      clip: rect(0vh, 100vw, 100vh, 0vw);
      .img {
        width: 100vw;
        height: 100vh;
        background: url(${MVisualImg}) no-repeat center;
        background-size: cover;
      }
    }
    &__text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: var(--color-white);
      text-align: left;
      .text-title {
        margin-bottom: 2.4rem;
        font-weight: 900;
        font-family: var(--font-GmarketSans);
        line-height: 1.25;
        text-transform: uppercase;
        span {
          display: block;
          color: #fff;
          letter-spacing: 0.75rem;
          -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
          clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
          transform: translateY(-50px);
          opacity: 0;
          animation: titleAnimation ease 3s 0.6s forwards;
          &:first-child {
            animation-delay: 0.5s;
          }
          &:last-child {
            animation-delay: 0.7s;
          }
        }
      }
      @keyframes titleAnimation {
        0% {
          transform: translateY(-50px);
          opacity: 0;
          -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
          clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
        }
        20% {
          transform: translateY(0);
          opacity: 1;
          -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
          clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
        }
        100% {
          transform: translateY(0);
          opacity: 1;
          -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
          clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
        }
        /* 100% {
        transform: translateY(50px);
        opacity: 0;
        -webkit-clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
        clip-path: polygon(100% 0, 100% -0%, 0 100%, 0 100%);
      } */
      }
      .text {
        &-type1 {
          font-weight: 100;
          animation: fadeInUp 0.6s 0.75s both;
        }
        &-type2 {
          font-weight: 100;
          animation: fadeInUp 0.6s 1s both;
        }
        @-webkit-keyframes fadeInUp {
          0% {
            opacity: 0;
            transform: translateY(100px);
          }

          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
        @keyframes fadeInUp {
          0% {
            opacity: 0;
            transform: translateY(70px);
          }

          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }
    .scroll-down {
      position: absolute;
      left: 50%;
      bottom: 2rem;
      transform: translateX(-50%);
      color: var(--color-white);
      p {
        margin-bottom: 0.8rem;
        font-size: 1.4rem;
        font-weight: 700;
      }
      &__bg {
        position: relative;
        left: 50%;
        width: 1px;
        height: 5rem;
        background-color: var(--color-white-rgba-25);
      }
      &__bar {
        position: relative;
        /* left: 50%; */
        left: calc(50% + 0.5px);
        transform: translateX(-50%);
        width: 1px;
        height: 2rem;
        background-color: var(--color-white);
        animation: move-down 1.2s both linear infinite;
        @keyframes move-down {
          0% {
            opacity: 0;
            transform: translateY(0) translateX(-1px);
          }
          35% {
            opacity: 1;
            transform: translateY(0.75rem) translateX(-1px);
          }
          70% {
            opacity: 1;
            transform: translateY(2rem) translateX(-1px);
          }
          100% {
            opacity: 0;
            transform: translateY(3rem) translateX(-1px);
          }
        }
      }
    }
  }

  .c-work {
    position: relative;
    color: var(--color-white);
    background: url(${MOurWorkImg}) no-repeat center;
    background-size: cover;
    &__title h2 {
      color: #fff;
    }
    .work-swiper {
      &__control {
        position: relative;
        margin-top: 3.4rem;
        .swiper-pagination {
          display: inline-block;
          margin: 0 1rem;
          span {
            font-size: 1.6rem;
            margin: 0 0.25rem;
          }
          &-current,
          &-total {
            font-weight: 700;
          }
          &-total {
            color: var(--color-white-rgba-50);
          }
        }
        .swiper-button-prev,
        .swiper-button-next {
          position: static;
          display: inline-block;
          margin-top: 0;
          color: var(--color-white);
          background-color: transparent;
        }
        .swiper-button-prev {
          left: 0;
        }
        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-size: 22px;
        }
      }
    }
  }
  .c-playground {
    position: relative;
    background: var(--color-gray-f7f7f7);
    overflow: hidden;
    /* &::after {
      content: 'PLAYGROUND';
      position: absolute;
      left: 50%;
      bottom: -35px;
      transform: translateX(-50%);
      color: var(--color-black-rgba-5);
      font-size: 22rem;
      font-weight: 900;
      line-height: 1;
      z-index: 0;
    } */
    &__link {
      margin-top: 8rem;
      padding: 1.8rem 8.5rem;
      font-size: 2.1rem;
      font-weight: 500;
      span {
        position: relative;
        display: inline-block;
        padding-right: 4rem;
        &::after {
          content: '';
          position: absolute;
          top: 52%;
          right: 0;
          transform: translateY(-50%);
          display: inline-block;
          width: 2.6rem;
          height: 2rem;
          background: url(${ArrowIcon}) no-repeat center / 2.6rem;
        }
      }
    }
    &__list {
      margin-right: 5rem;
    }
  }

  @media ${({ theme }) => theme.media.xlMin} {
    .c-work {
      &__title {
        flex: 0 0 665px;
      }
    }
  }
  @media ${({ theme }) => theme.media.lgMin} {
    .container {
      height: 100vh;
      h2 {
        font-size: 10rem;
      }
    }
    .c-visual {
      &.is-scroll {
        .c-visual__text {
          left: 0;
        }
      }
      &__text {
        right: 1.6rem;
        .text-title {
          span {
            font-size: 12.5rem;
          }
        }
        p {
          font-size: 2.3rem;
          padding-left: 0.8rem;
        }
      }
    }
    .c-work {
      .inner {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        text-align: left;
      }
      &__list {
        width: 52.2vw;
      }
    }
    .c-playground {
      .inner {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: space-between;
        width: calc(100% - 32px);
        text-align: left;
      }
      &__title {
        order: 2;
      }
    }
  }
  @media ${({ theme }) => theme.media.lg} {
    .container {
      h2 {
        font-size: 7rem;
      }
    }
    .c-visual {
      &__text {
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        .text-title {
          span {
            font-size: 12rem;
          }
        }
        p {
          font-size: 2rem;
          text-align: center;
        }
      }
      /* .scroll-down {
        display: none;
      } */
    }
    .c-work {
      .inner {
        padding-top: 8rem;
        padding-bottom: 8rem;
      }
      &__list {
        width: 100vw;
        margin-top: 6rem;
      }
    }
    .c-playground {
      .inner {
        flex-direction: column;
        padding-top: 8rem;
        padding-bottom: 8rem;
      }
      &__list {
        display: none;
      }
    }
  }
`;

MainView.propTypes = {
  popups: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  banners: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
};

MainView.defaultProps = {
  popups: [],
  banners: [],
};

export default MainView;
