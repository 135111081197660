import React from 'react';
import styled from 'styled-components';

import Button from '../../atoms/Button';

import SubContainer from '../../atoms/SubContainer';
import SubVisualImg from '../../../assets/img/product/sub-visual.jpg';
import PlaygroundIco1 from '../../../assets/img/catchfun/catchfun001.png';
import PlaygroundIco2 from '../../../assets/img/catchfun/catchfun002.png';
import PlaygroundIco3 from '../../../assets/img/catchfun/catchfun003.png';
import PlaygroundIco4 from '../../../assets/img/catchfun/catchfun004.png';
import PlaygroundIco5 from '../../../assets/img/catchfun/catchfun005.png';
import PlaygroundIco6 from '../../../assets/img/catchfun/catchfun006.png';

function CatchfunView() {
  return (
    <StyledSubContainer
      isVisible
      title="캐치펀"
      subTxt="캐치펀은 내 주변 할인 혜택을 획득할 수 있는 쿠폰획득 어플 입니다. 내 주변 쿠폰을 캐치해서 다양항 혜택을 누려 보세요. 내 손으로 혜택을 잡다. 캐치~펀!"
      subVisual={SubVisualImg}
      className="sub-catchfun"
    >
      <article className="c-playground">
        <div className="inner-container__sub">
          <h3>캐치펀</h3>
          <p className="info-txt">
            출근길에 커피한잔.. 점심은 핫플레이스 식당.. 저녁은 가볍게 술한잔..
            그냥 이용하신다구요?
          </p>
          <p className="info-txt">
            캐치펀으로 쿠폰을 획득하여 상점에서 할인 및 혜택을 받아보세요.
          </p>
          <p className="info-txt">
            애플리케이션만 실행하면 내 주변에 있는 할인 쿠폰들을 획득할 수
            있습니다.
          </p>
          <p className="info-txt">
            내 손으로 혜택과 재미를 동시에 잡는 캐치~펀~!
          </p>
          <ul className="c-playground__list">
            <li className="list-item--type1">
              <strong>캐치펀</strong>
              <p>
                이젠 어렵게 할인쿠폰 찾지말고 캐치펀으로 내위치에 있는 쿠폰을
                획득해 보세요.
              </p>
            </li>
            <li className="list-item--type2">
              <strong>손쉬운 쿠폰 획득</strong>
              <p>
                내가 있는 위치의 쿠폰들을 확인하고 쿠폰을 손쉽게 획득할 수
                있습니다.
              </p>
            </li>
            <li className="list-item--type3">
              <strong>필요한 혜택만 쏙!쏙!</strong>
              <p>내게 필요한 혜택을 확인해서 쿠폰을 다운로드 할 수 있어요~</p>
            </li>
            <li className="list-item--type4">
              <strong>쿠폰 획득</strong>
              <p>내 주변에 있는 여러 쿠폰들을 손쉽게 획득 가능합니다.</p>
            </li>
            <li className="list-item--type5">
              <strong>보유쿠폰 관리</strong>
              <p>
                내가 획득한 쿠폰을 가족이나 친구에게 선물해서 혜택을 같이 누려
                보세요~
              </p>
            </li>
            <li className="list-item--type6">
              <strong>손쉬운 쿠폰 사용하기</strong>
              <p>쿠폰만 보여주면 쿠폰 사용은 끝~!</p>
            </li>
          </ul>
          <div className="c-contactUs__btn">
            <Button href="/policy" size="md" variant="default">
              개인정보처리방침
            </Button>
          </div>
        </div>
      </article>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled(SubContainer)`
  h3 {
    margin: 0 auto 5rem !important;
    font-weight: 700;
  }
  h3,
  .info-txt {
    text-align: center;
    line-height: 4rem;
  }
  .inner-container__sub {
    padding {
    }
  }
  .c-playground {
    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8rem;
      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% / 3 - 1rem);
        margin: 1rem 0;
        padding: 5rem;
        text-align: center;
        &:nth-child(even) {
          background: var(--color-gray-F6F8FC);
        }
        &[class^='list-item'] {
          strong {
            font-size: 2.7rem;
            &::before {
              content: '';
              display: block;
              width: 24rem;
              // height: 14.4rem;
              height: 52rem;
              margin: 0px auto 3rem;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 24rem;
            }
          }
          &[class*='--type1'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco1});
              }
            }
          }
          &[class*='--type2'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco2});
              }
            }
          }
          &[class*='--type3'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco3});
              }
            }
          }
          &[class*='--type4'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco4});
              }
            }
          }
          &[class*='--type5'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco5});
              }
            }
          }
          &[class*='--type6'] {
            strong {
              &::before {
                background-image: url(${PlaygroundIco6});
              }
            }
          }
        }
        p {
          font-size: 2rem;
        }
      }
    }
  }
  .c-contactUs__btn {
    margin-top: 8rem;
    text-align: center;
    a {
      padding-right: 10rem;
      padding-left: 10rem;
      font-size: 1.9rem;
      font-weight: 500;
    }
  }
  @media ${({ theme }) => theme.media.xl} {
  }
  @media ${({ theme }) => theme.media.lg} {
    .inner-container {
      .info-txt {
        padding: 0 5rem;
      }
    }
    .c-playground {
      &__list {
        li {
          width: calc(100% / 2 - 1rem);
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMin} {
    .c-playground {
      &__list {
        li {
          // height: 40rem;
          p {
            min-height: 55px;
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-playground {
      &__list {
        li {
          width: calc(100%);
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.sm} {
  }
`;

export default CatchfunView;
